<template>
    <div>
        <main>
            <div class="container">
                <div class="row justify-content-center mt-3">
                    <div class="col-lg-10">
                        <div class="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                            <div class="card-body h250 p-0 rounded-xxl overflow-hidden m-3 mb-0">
                                <div class="prof-cover bg-primary-gradiant"></div>
                            </div>
                            <div v-if="userData" class="card-body p-0 position-relative mx-3 d-flex mb-3 profile-header">
                                <div class="col-3">
                                    <figure class="profile-avatar w-100 text-center position-relative">
                                        <img class="position-relative bg-primary-gradiant shadow-xss" :src="userData.userDp?userData.userDp:'https://d15bmhsw4m27if.cloudfront.net/public/dp.jpg'">
                                    </figure>
                                </div>
                                <div class="col-5">
                                    <div class="details-box w-100 pt-2">
                                        <h4 class="u-fname w-100 fw-800" v-html="userData.fullName"></h4> 
                                        <p class="w-100 m-0">@<b>{{userData.username}}</b></p> 
                                        <div class="menu-category">
                                            <ul class="menu d-flex">
                                                <li><span class="fw-800">{{postsCount}}</span> Posts </li>
                                                <li class="ms-3"><span class="fw-800">{{userData.followers}}</span> Followers </li>
                                                <li class="ms-3"><span class="fw-800">{{userData.following}}</span> Following </li>
                                            </ul>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="d-flex profile-actions pt-2 justify-content-end">
                                        <div class="d-inline-flex" v-if="userLoggedIn.userId!=userData._id">
                                            <button class="btn follow-btn px-4"  @click.prevent="userFollowAct"><i class="mico-feather-9"></i><span>{{userData.isFollowing?'Following':'Follow'}}</span></button>
                                        </div>
                                        <div class="d-inline-flex" v-if="userLoggedIn.userId==userData._id">
                                            <DropDown dropdownHandle='<i class="mico-android-more-horizontal"></i>' class="user-actins" :menuList="userActinsMenuList"></DropDown>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-10">
                        <div class="row mb-3 mt-3">
                            <div class="col-lg-4">
                                <div class="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                                    <div class="card-body d-block p-4">
                                        <h4 class="fw-800 mb-3">About</h4>
                                        <p class="fw-500 lh-1-5 mb-0 user-abt" v-if="userData.bio" v-html="aboutToHtml"></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 position-relative user-posts">
                                <PostListItem :postItem="item" :index="index" v-for="(item, index) in postList" :key="item._id"></PostListItem>
                                <Loader :stat='loader' theme="skeleton-feed"></Loader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <PostViewer v-if="postFullView"  :postData="postFullViewData"></PostViewer>
    </div>
</template>
<style>
    .user-posts .loader-main{
        position: relative;
        background: transparent;
    }
    .user-actins .btn{
        background: var(--white-clr2);
        border-radius: 50%;
        width: 38px;
        height: 38px;
        text-align: center;
        padding: 0;
        line-height: 2.4;
    }
    .user-abt, .user-abt p{
        width: 100%;
        text-align-last: left;
    }
    .user-abt a{
        color: var(--dark-color);
        font-weight: 700;
    }
</style>
<script>
import axios from '@/plugins/axios'
import { mapGetters } from 'vuex'
import { marked } from 'marked'
export default {
    data () {
        return {
            page:1,
            userData:false,
            postsCount:0,
            profileId:false,
            postList:[],
            loader:true,
            userActinsMenuList:[
                { link:'/settings/editprofile', menuInner:'Edit Profile' ,icon:'mico-feather-9'},
                { link:'/settings/password', menuInner:'Change Password' ,icon:'mico-key'},
                { link:'/logout', menuInner:'Logout' ,icon:'mico-sign-out'},
            ],
            postFullView:false,
            postFullViewData:{}
        }
    },
    computed: {
        ...mapGetters({ authToken: 'authToken',userLoggedIn:'userData'}),
        aboutToHtml(){
            var text = this.userData.bio
            text = text.replace(/(\b(https?|http):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z09+&@#\/%=~_|])/img, '<a href="$1" target="_blank">$1</a>');
            text = text.replace(/#(\w+)/g, '<a href="'+window.location.origin+'/hashtag/$1">#$1 </a> '); 
            text = text.replace(/@(\w+)/g, '<a href="'+window.location.origin+'/$1">@$1 </a> ');
            return  text;
        }
    },
    created: function () {
        window.scrollTo(0, 0)
    },
    beforeMount() {
        this.getUserData()
    },
    mounted() {
        this.getNextPage();
    },
     methods: {
        getUserData: function () {
            var self = this
            var username = this.$route.params.userid.toLowerCase();;

            axios.get('/api/user/profile?profileid='+username).then(response => {
                self.userData = response.data.data;
                self.profileId =  response.data.data._id;
            }).catch(error => {
                self.userData = false
                this.$router.push({ name: '404' }) 
            })
        },
        getFeedData: function () {
            var self = this
            if (self.page == 0 ) return false;
            self.loader=true
            axios.get('/api/posts/public?profileid='+self.profileId+'&page='+self.page).then(response => {
                self.page = response.data.data.next;
                self.postList=[];
                response.data.data.list.forEach(item => {  self.postList.push(item);  self.postsCount=response.data.data.count});
                self.loader=false
            }).catch(error => {
                self.loader=false
                self.page =0
            })
        },
        getNextPage:function () {
            window.onscroll = () => {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight == document.documentElement.offsetHeight;
                if (bottomOfWindow) {
                  this.getFeedData()
                }
            }
        },
        userFollowAct:function(e){
            var flwbtn = document.querySelector('.follow-btn')
            flwbtn.disabled=true
            var self = this
            axios.post('/api/user-act/follow', { profileid:self.profileId}).then(async response => {
                if(response.data.data){
                    if(response.data.data.action=="follow") self.userData.isFollowing=true
                    else self.userData.isFollowing=false
                    self.userData.followers = response.data.data.followers;
                    self.userData.following = response.data.data.following;
                    this.getUserData()
                }
                flwbtn.disabled=false
            }).catch(error => {
                flwbtn.disabled=false
            })
        }
    },
    watch:{
        '$route.params.userid': function(){
            if(this.$route.params.userid){
                this.page = 1;
                this.profileId = false
                this.userPosts=[];
                this.postsCount=0;
                this.getUserData()
            }
        },
        'profileId':function(){
            this.getFeedData(); 
        },
        postFullView:function(){
        if(this.postFullView) document.querySelector('body').classList.add('full-view');
         else document.querySelector('body').classList.remove('full-view');
        }
    }
}
</script>